import "./App.css";
import { Application, ArcwareApplication, ArcwareInit, PixelStreaming } from "@arcware-cloud/pixelstreaming-websdk";
import React, { useState, useRef, useEffect } from "react";
var isMobile = require('detect-touch-device');


let arcwareApplication;
let pixelStreamApp;
function App() {
  const videoContainerRef = useRef(null);

  const [applicationResponse, setApplicationResponse] = useState("");
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const [delayTime, setDelayTime] = useState(20000);

  const[isListenerAdded,setIsListnerAdded] = useState(false);

  const [loading, setLoading] = useState(true);
  const [inQueue, setInQueue] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  const [showMic, setShowMic] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showEnterPassword, setShowEnterPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [queueMessage, setQueueMessage] = useState();
  const [styleVideo, setStyleVideo] = useState({ position: 'relative', zIndex: 1000, width: "100vw", height: "100vh" });

  const [hasMicAccess, setHasMicAccess] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
      // Attempt to play the audio
      if (audioRef.current) {
          audioRef.current.play().catch(error => {
              console.log("Autoplay failed, user interaction needed.", error);
          });
      }
  }, []);

  useEffect(() => {
    const requestMicrophoneAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setHasMicAccess(true);
        console.log('Microphone access granted');
        // Handle the audio stream (e.g., connect to WebRTC or WebAudio API)
      } catch (err) {
        console.error('Microphone access denied', err);
        setHasMicAccess(false);
      }
    };

    requestMicrophoneAccess();
  }, []);


  useEffect(() => {
    // Parse message from the URL
    const url = window.location.href;
    let message = url.split('/').pop();
    
    if(message.includes('?')){
      var newMessage = message.split('?');
       message = newMessage[0];
    }
    setMessage(message);

    // Fetch data from endpoint if message exists
    if (message) {
        fetch(`https://api.streampixel.io/pixelStripeApi/projects/shareId/${message}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Project not found');
          }
          return response.json();
        })
        .then(data => {
          // Check status and subscriptionEndDate
          setShowEnterPassword(data.passwordProtect);
          setPassword(data.password);
          setDelayTime(data.delay);
          setShowBtn(data.showBtn);
          setShowMic(data.showMic);

          if (data.status === true) {
            setStatus('active');
          } else {
            setStatus('inactive');
          }
        })
        .catch(error => {
          setStatus('notfound');
        });
    } else {
      // Handle invalid URL scenario
      setStatus('invalidurl');
    }
  }, [message]);

  useEffect(() => {
    if (message !== null && status === 'active') {
        addMessageListener();

      if(showEnterPassword == false){
      const { Application,PixelStreaming } = ArcwareInit({
        shareId: message
      }, {
        initialSettings: {
            StartVideoMuted: false,
            AutoConnect: true,
            AutoPlayVideo: true,
            UseMic: showMic,
            GamepadInput: false,
            TouchInput: true,
            XRControllerInput: false
          },
          settings: {
            audioButton:showBtn,
            fullscreenButton: showBtn,
            settingsButton: false,
            connectionStrengthIcon: showBtn,
          loveLetterHandler: (message) => {
            if (message.reason === "LL: Start connection to stream signalling.") {
              if (videoContainerRef.current) {
                videoContainerRef.current.appendChild(Application.rootElement);
                delay(delayTime).then(()=>{
                setLoading(false);
                window.parent.postMessage("loadingComplete", "*");     

                setStyleVideo({ position: 'relative', zIndex: 1000, width: "100vw", height: "100vh"});
            let consoleDescriptor = {
                touch: true
              };

              if(isMobile.isMobile == true){
              Application.emitUIInteraction(consoleDescriptor);
              }
                })
              }
            }
          },
 
        },
      });

      pixelStreamApp = PixelStreaming;
      arcwareApplication =  Application ;


     

      Application.getApplicationResponse((response) => {
     
        if (response.includes("https")) {
          window.open(response);
        }
        window.parent.postMessage(response, "*");     
        setApplicationResponse(response);
      });

      PixelStreaming.queueHandler.add((message)=>{
      })

      if(showMic == true){
        PixelStreaming.toggleMic(true, true);
      
      }
    }
    
   
      if(passwordValid == true){

        const { Application,PixelStreaming } = ArcwareInit({
          shareId: message
        }, {
          initialSettings: {
              StartVideoMuted: false,
              AutoConnect: true,
              AutoPlayVideo: true,
              UseMic: showMic,
              GamepadInput: false,
              TouchInput: true,
              XRControllerInput: false
            },
            settings: {
              audioButton:showBtn,
              fullscreenButton: showBtn,
              settingsButton: false,
              connectionStrengthIcon: showBtn,
            loveLetterHandler: (message) => {
            
              if (message.reason === "LL: Start connection to stream signalling.") {
                if (videoContainerRef.current) {
                  videoContainerRef.current.appendChild(Application.rootElement);
                  delay(delayTime).then(()=>{
                  setLoading(false);
                  window.parent.postMessage("loadingComplete", "*");       
                  setStyleVideo({ position: 'relative', zIndex: 1000, width: "100vw", height: "100vh"});
              let consoleDescriptor = {
                  touch: true
                };
  
                if(isMobile.isMobile == true){
                Application.emitUIInteraction(consoleDescriptor);
                }
                  })
                }
              }
            },
   
          },
        });
        pixelStreamApp = PixelStreaming;
        
        arcwareApplication =  Application ;
       
      
         
        Application.getApplicationResponse((response) => {
       
          if (response.includes("https")) {
            window.open(response);
          }
          window.parent.postMessage(response, "*");     
          setApplicationResponse(response);
        });
  
        PixelStreaming.queueHandler.add((message)=>{
        })

  
        if(showMic == true){
          PixelStreaming.toggleMic(true, true);
        
        }
      }
    
  }
  }, [message, status, passwordValid]);





    function addMessageListener() {
    
     
    
      if(isListenerAdded == false){
      window.addEventListener('message', (event) => {
       
        setIsListnerAdded(true);
       
        const message = event.data;

     
        if (message === "terminateSession") {
          handleTerminateSession();
        }
         
        if (message.message === "muteAudio") {

          const videoElement = arcwareApplication.stream.videoElementParent.querySelector("video");
        if (videoElement) {

          arcwareApplication.stream.toggleAudio(videoElement, true); 
         }
         

        }


         
        if (message.message === "unMuteAudio") {
           const videoElement = arcwareApplication.stream.videoElementParent.querySelector("video");

        if (videoElement) {
          arcwareApplication.stream.toggleAudio(videoElement, false); 
         }
         
        }
        handleSendCommand(message);
      });
    }
    }
  
  const handleTerminateSession =()=>{

      pixelStreamApp.disconnect();
      arcwareApplication && arcwareApplication.stream.disconnect();
    
  }
  
    const handleSendCommand = (descriptor) => {
   

     pixelStreamApp && pixelStreamApp.emitUIInteraction(descriptor);
    
    };
  


  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const handlePasswordSubmit = async () => {
    try {
      const res = await fetch('https://api.streampixel.io/pixelStripeApi/projects/passwordCheck', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: enteredPassword,shareId:message })
      });


    
      if (res.status === 200) {
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
        alert('Invalid password');
      }
    } catch (error) {
      console.error('Error validating password:', error);
      setPasswordValid(false);
    }
  };

  let uiContent;

  if (status === 'inactive') {
    uiContent = (
      <div style={{ width: "100vw", height: "100vh",top:"0px",bottom:"0px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <p>Not Allowed</p>
      </div>
    );
  } else if (status === 'notfound' || status === 'invalidurl') {
    uiContent = (
      <div style={{ width: "100vw", height: "100vh",top:"0px",bottom:"0px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <p>Not Valid URL</p>
      </div>
    );
  } else {
    uiContent = (
      <div>
    
    {inQueue?(<>
    
      <div style={{ position: 'absolute', top: 0,bottom:0, left: 0, width: '100vw', height: '100vh', zIndex: 100000, backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">you are in queue ...</span>
              </div>
            </div>

    </>):(<>
      <div ref={videoContainerRef} style={styleVideo}>
      <audio ref={audioRef} />
      </div>
        {loading && (
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 100000, backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
    </>)}
  </div>
    );
  }

  return (
<> 
{!showEnterPassword || passwordValid ? (
    <div>
   


      {uiContent}
    </div>
  ) : (
    <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <input
        type="password"
        placeholder="Enter Password"
        value={enteredPassword}
        onChange={(e) => setEnteredPassword(e.target.value)}
        style={{ padding: '10px', fontSize: '16px', marginBottom: '10px' }}
      />
      <button onClick={handlePasswordSubmit} style={{ padding: '10px 20px', fontSize: '16px' }}>Submit</button>
    </div>
  )}
    </>

  );
}

export default App;
